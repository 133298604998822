// src/components/CompanyForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import FileUpload from './FileUpload';
import ListManager from './ListManager';
import { 
  Building2, 
  Contact, 
  MapPin, 
  Share2, 
  Phone, 
  Calculator, 
  Users, 
  UserSquare2,
  ShoppingCart,
  Megaphone,
  Shield,
  Globe,
  Lock,
  FileText,
  Mail,
  Smartphone,
  Facebook,
  Linkedin
} from 'lucide-react';

const CompanyForm = () => {
  // State pentru datele principale ale formularului
  const [formData, setFormData] = useState({
    // Secțiunea 1: Informații Generale despre Companie
    companyName: '',
    fiscalCode: '',
    contactPerson: '',
    contactEmail: '',
    contactPhone: '',
    website: '',

    // Secțiunea 3: Social Media
    facebook: '',
    linkedin: '',
    otherSocial: '',

    // Secțiunea 4: Date de Contact pentru Suport Clienți
    supportPhone: '',
    supportEmail: '',
    whatsapp: '',

    // Modul de Contabilitate Automată
    accountingSoftware: '',
    eInvoicing: false,
    eFacturaIntegration: false,
    financialReports: '',

    // Modul de Resurse Umane
    salarySoftware: '',
    leaveManagement: false,
    employeePortal: false,

    // Modul de Relații cu Clienții
    crmSystem: '',
    communicationChannels: {
      phone: false,
      email: false,
      whatsapp: false
    },
    chatbot: false,
    customerAnalytics: false,

    // Modul de Vânzări și Stocuri
    inventorySystem: '',
    aiPredictiveSales: false,
    stockOptimization: false,

    // Modul de Marketing
    marketingPlatforms: '',
    autoContentGeneration: false,
    marketingNeeds: '',

    // Modul de Securitate
    currentSecuritySolutions: '',
    threatMonitoring: '',

    // Modul Website
    newWebsite: false,
    websiteFeatures: '',
    googleIntegration: false,

    // GDPR
    gdprCompliance: false,
    specialAccess: false
  });

  // State pentru fișiere
  const [files, setFiles] = useState({
    revisal: [],
    jobDescriptions: [],
    accountingDocs: [],
    contracts: []
  });

  // State pentru locații
  const [locations, setLocations] = useState([]);

  // State pentru erori și loading
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handlers
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: type === 'checkbox' ? checked : value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleFileUpload = (category) => (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles(prev => ({
      ...prev,
      [category]: [...prev[category], ...newFiles]
    }));
  };

  const handleFileRemove = (category, index) => {
    setFiles(prev => ({
      ...prev,
      [category]: prev[category].filter((_, i) => i !== index)
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.companyName) newErrors.companyName = 'Numele companiei este obligatoriu';
    if (!formData.fiscalCode) newErrors.fiscalCode = 'Codul fiscal este obligatoriu';
    if (!formData.contactPerson) newErrors.contactPerson = 'Persoana de contact este obligatorie';
    if (!formData.contactEmail) {
      newErrors.contactEmail = 'Email-ul este obligatoriu';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.contactEmail)) {
      newErrors.contactEmail = 'Email invalid';
    }
    if (!formData.contactPhone) newErrors.contactPhone = 'Telefonul este obligatoriu';
    
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error('Vă rugăm să completați toate câmpurile obligatorii');
      return;
    }

    setIsSubmitting(true);

    try {
      const formDataToSend = new FormData();
      
      // Adăugăm datele formularului
      formDataToSend.append('formData', JSON.stringify(formData));
      
      // Adăugăm locațiile
      formDataToSend.append('locations', JSON.stringify(locations));
      
      // Adăugăm fișierele
      Object.keys(files).forEach(category => {
        files[category].forEach(file => {
          formDataToSend.append(`files_${category}[]`, file);
        });
      });

      const response = await axios.post('https://aipro.ro/api/company-information', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Informațiile au fost salvate cu succes!');
    } catch (error) {
      toast.error(error.response?.data?.message || 'A apărut o eroare la salvarea datelor');
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderInput = (label, name, type = 'text', required = false, icon = null) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        <span className="flex items-center">
          {icon && <span className="mr-2">{icon}</span>}
          {label} {required && <span className="text-red-500 ml-1">*</span>}
        </span>
      </label>
      <input
        type={type}
        name={name}
        value={formData[name]}
        onChange={handleInputChange}
        className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
          errors[name] ? 'border-red-500' : 'border-gray-300'
        }`}
      />
      {errors[name] && (
        <p className="mt-1 text-sm text-red-500">{errors[name]}</p>
      )}
    </div>
  );

  const renderCheckbox = (label, name) => (
    <div className="mb-4">
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          name={name}
          checked={formData[name]}
          onChange={handleInputChange}
          className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        />
        <span className="text-sm font-medium text-gray-700">{label}</span>
      </label>
    </div>
  );

  const renderTextarea = (label, name) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <textarea
        name={name}
        value={formData[name]}
        onChange={handleInputChange}
        rows="3"
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
      />
    </div>
  );

  const renderSection = (title, icon, children) => (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
        {icon}
        <span className="ml-2">{title}</span>
      </h2>
      {children}
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <form onSubmit={handleSubmit} className="max-w-4xl mx-auto space-y-8">
        {/* Secțiunea 1: Informații Generale despre Companie */}
        {renderSection("Informații Generale despre Companie", <Building2 className="h-6 w-6 text-blue-600" />, (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {renderInput('Nume companie', 'companyName', 'text', true, <Building2 className="h-5 w-5" />)}
            {renderInput('Cod fiscal', 'fiscalCode', 'text', true, <FileText className="h-5 w-5" />)}
            {renderInput('Persoană de contact', 'contactPerson', 'text', true, <Contact className="h-5 w-5" />)}
            {renderInput('Email', 'contactEmail', 'email', true, <Mail className="h-5 w-5" />)}
            {renderInput('Telefon', 'contactPhone', 'tel', true, <Phone className="h-5 w-5" />)}
            {renderInput('Website', 'website', 'url', false, <Globe className="h-5 w-5" />)}
          </div>
        ))}

        {/* Secțiunea 2: Puncte de Lucru */}
        {renderSection("Puncte de Lucru", <MapPin className="h-6 w-6 text-blue-600" />, (
          <ListManager
            label="Adrese puncte de lucru"
            items={locations}
            onAdd={(item) => setLocations([...locations, item])}
            onRemove={(index) => setLocations(locations.filter((_, i) => i !== index))}
            onEdit={(index, newValue) => {
              const newLocations = [...locations];
              newLocations[index] = newValue;
              setLocations(newLocations);
            }}
          />
        ))}

        {/* Secțiunea 3: Social Media */}
        {renderSection("Canale Social Media", <Share2 className="h-6 w-6 text-blue-600" />, (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {renderInput('Facebook', 'facebook', 'url', false, <Facebook className="h-5 w-5" />)}
            {renderInput('LinkedIn', 'linkedin', 'url', false, <Linkedin className="h-5 w-5" />)}
            {renderInput('Alte rețele sociale', 'otherSocial', 'text', false, <Share2 className="h-5 w-5" />)}
          </div>
        ))}

        {/* Secțiunea 4: Customer Support */}
        {renderSection("Date de Contact pentru Suport Clienți", <Phone className="h-6 w-6 text-blue-600" />, (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {renderInput('Telefon suport', 'supportPhone', 'tel', false, <Phone className="h-5 w-5" />)}
            {renderInput('Email suport', 'supportEmail', 'email', false, <Mail className="h-5 w-5" />)}
            {renderInput('WhatsApp', 'whatsapp', 'tel', false, <Smartphone className="h-5 w-5" />)}
          </div>
        ))}

        {/* Modulul de Contabilitate */}
        {renderSection("Modul de Contabilitate Automată", <Calculator className="h-6 w-6 text-blue-600" />, (
          <>
            {renderInput('Software contabilitate folosit', 'accountingSoftware')}
            {renderCheckbox('Emitere și primire facturi electronice', 'eInvoicing')}
            {renderCheckbox('Integrare E-factura și Office 360', 'eFacturaIntegration')}
            {renderTextarea('Specificați tipul de rapoarte financiare necesare', 'financialReports')}
            <FileUpload
              label="Atașați fișa Revisal actualizată"
              name="revisal"
              accept=".pdf,.doc,.docx"
              multiple={false}
              files={files.revisal}
              onChange={handleFileUpload('revisal')}
              onRemove={(index) => handleFileRemove('revisal', index)}
            />
          </>
        ))}

        {/* Modulul de Resurse Umane */}
        {renderSection("Modul de Resurse Umane", <Users className="h-6 w-6 text-blue-600" />, (
          <>
            {renderInput('Software calcul salarii', 'salarySoftware')}
            {renderCheckbox('Gestionare concedii conectată la contabilitate', 'leaveManagement')}
            {renderCheckbox('Portal angajați și e-learning', 'employeePortal')}
            <FileUpload
              label="Atașați documentele de muncă relevante"
              name="contracts"
              accept=".pdf,.doc,.docx"
              multiple={true}
              files={files.contracts}
              onChange={handleFileUpload('contracts')}
              onRemove={(index) => handleFileRemove('contracts', index)}
            />
          </>
        ))}

        {/* Modulul CRM */}
        {renderSection("Modul de Relații cu Clienții", <UserSquare2 className="h-6 w-6 text-blue-600" />, (
          <>
            {renderInput('Sistem CRM folosit', 'crmSystem')}
            <div className="mb-4">
              <p className="text-sm font-medium text-gray-700 mb-2">Canale de comunicare folosite:</p>
              {renderCheckbox('Telefon', 'communicationChannels.phone')}
              {renderCheckbox('Email', 'communicationChannels.email')}
              {renderCheckbox('WhatsApp', 'communicationChannels.whatsapp')}
            </div>
            {renderCheckbox('Chatbot inteligent', 'chatbot')}
            {renderCheckbox('Analiză date clienți', 'customerAnalytics')}
          </>
        ))}

        {/* Modulul de Vânzări și Stocuri */}
        {renderSection("Modul de Previzionare a Vânzărilor și Optimizarea Stocurilor", <ShoppingCart className="h-6 w-6 text-blue-600" />, (
          <>
            {renderInput('Sistem management stocuri', 'inventorySystem')}
            {renderCheckbox('Estimare vânzări cu modele predictive AI', 'aiPredictiveSales')}
            {renderCheckbox('Optimizare stocuri și reprezentare grafică', 'stockOptimization')}
          </>
        ))}

        {/* Modulul de Marketing */}
        {renderSection("Modul de Marketing Automatizat", <Megaphone className="h-6 w-6 text-blue-600" />, (
          <>
            {renderInput('Platforme marketing folosite', 'marketingPlatforms')}
            {renderCheckbox('Generare automată conținut social media', 'autoContentGeneration')}
            {renderTextarea('Necesități specifice pentru campanii de marketing', 'marketingNeeds')}
          </>
        ))}

        {/* Modulul de Securitate */}
        {renderSection("Modul de Securitate Cibernetică", <Shield className="h-6 w-6 text-blue-600" />, (
          <>
            {renderInput('Soluții de securitate IT actuale', 'currentSecuritySolutions')}
            {renderTextarea('Necesități specifice de monitorizare și detectare a amenințărilor', 'threatMonitoring')}
          </>
        ))}

        {/* Modulul Website */}
        {renderSection("Modul de Dezvoltare Website și Aplicații Mobile", <Globe className="h-6 w-6 text-blue-600" />, (
          <>
            {renderCheckbox('Realizare website nou', 'newWebsite')}
            {renderTextarea('Specificați cerințele principale pentru website', 'websiteFeatures')}
            {renderCheckbox('Integrare Google Analytics și Google Search Console', 'googleIntegration')}
          </>
        ))}

        {/* Secțiunea GDPR */}
        {renderSection("Politici GDPR și Securitate", <Lock className="h-6 w-6 text-blue-600" />, (
          <>
            {renderCheckbox('Implementare conformitate GDPR', 'gdprCompliance')}
            {renderCheckbox('Control special al accesului la aplicație', 'specialAccess')}
          </>
        ))}

        {/* Buton Submit */}
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => {
              if (window.confirm('Sigur doriți să resetați formularul? Toate datele introduse vor fi pierdute.')) {
                setFormData({});
                setFiles({
                  revisal: [],
                  jobDescriptions: [],
                  accountingDocs: [],
                  contracts: []
                });
                setLocations([]);
                setErrors({});
              }
            }}
            className="px-6 py-3 bg-gray-100 text-gray-700 rounded-md shadow-sm hover:bg-gray-200 
            focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all"
          >
            Resetează Formular
          </button>
          
          <button
            type="submit"
            disabled={isSubmitting}
            className={`px-6 py-3 bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-md 
            shadow-lg hover:from-blue-700 hover:to-blue-800 focus:outline-none focus:ring-2 
            focus:ring-blue-500 focus:ring-offset-2 transform hover:-translate-y-0.5 transition-all
            ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSubmitting ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Se trimite...
              </span>
            ) : (
              <span className="flex items-center">
                <span>Trimite Formular</span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyForm;