import React from 'react';
import { Shield, Info } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8 mt-16">
      <div className="container mx-auto max-w-4xl px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Shield className="h-6 w-6 text-blue-400" />
              <h3 className="text-xl font-semibold">Protecția Datelor</h3>
            </div>
            <p className="text-gray-300 leading-relaxed">
              Datele dumneavoastră sunt protejate în conformitate cu Regulamentul General privind 
              Protecția Datelor (GDPR). Informațiile furnizate vor fi utilizate exclusiv în scopul 
              dezvoltării soluției software solicitate.
            </p>
          </div>
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Info className="h-6 w-6 text-blue-400" />
              <h3 className="text-xl font-semibold">Informații Importante</h3>
            </div>
            <ul className="text-gray-300 space-y-2">
              <li>• Toate datele sunt stocate în siguranță pe servere din UE</li>
              <li>• Aveți dreptul să solicitați ștergerea datelor în orice moment</li>
              <li>• Datele sunt utilizate doar pentru scopul declarat</li>
              <li>• Nu partajăm informațiile cu terțe părți</li>
            </ul>
            <div className="mt-4 pt-4 border-t border-gray-700">
              <p className="text-sm text-gray-400">
                © {new Date().getFullYear()} AIPro. Toate drepturile rezervate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;