import React from 'react';
import { ClipboardCheck } from 'lucide-react';

const Header = () => {
  return (
    <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-12 px-4">
      <div className="container mx-auto max-w-4xl">
        <div className="flex items-center justify-center mb-4">
          <ClipboardCheck className="h-12 w-12 mr-4" />
          <h1 className="text-3xl font-bold">
            Chestionar Colectare Informații pentru Dezvoltare Software Personalizat
          </h1>
        </div>
        <div className="bg-white/10 rounded-lg p-6 backdrop-blur-sm">
          <p className="text-lg leading-relaxed">
            Stimate Client,<br /><br />
            Pentru a iniția dezvoltarea soluției AIPro, vă rugăm să completați formularul de mai jos. 
            Acest lucru ne va ajuta să colectăm toate informațiile necesare pentru a realiza 
            funcționalitățile și website-ul conform cerințelor dvs. În câmpurile marcate, puteți 
            atașa documentele relevante (e.g., situația angajaților, fișe de post, documente de 
            contabilitate, politica de prețuri).
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;