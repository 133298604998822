import React from 'react';
import { Upload, X, File } from 'lucide-react';

const FileUpload = ({ label, name, accept, multiple = false, onChange, files = [], onRemove }) => {
  return (
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {label}
      </label>
      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:border-blue-400 transition-colors">
        <div className="space-y-1 text-center">
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <div className="flex text-sm text-gray-600">
            <label htmlFor={name} className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
              <span>Încărcați un fișier</span>
              <input
                id={name}
                name={name}
                type="file"
                className="sr-only"
                accept={accept}
                multiple={multiple}
                onChange={onChange}
              />
            </label>
            <p className="pl-1">sau trageți și plasați</p>
          </div>
          <p className="text-xs text-gray-500">
            {multiple ? "Fișiere permise" : "Fișier permis"}: {accept}
          </p>
        </div>
      </div>

      {/* Lista fișierelor încărcate */}
      {files.length > 0 && (
        <div className="mt-4 space-y-2">
          {files.map((file, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-2 bg-gray-50 rounded-md"
            >
              <div className="flex items-center space-x-2">
                <File className="h-5 w-5 text-gray-400" />
                <span className="text-sm text-gray-600">{file.name}</span>
                <span className="text-xs text-gray-400">
                  ({(file.size / 1024 / 1024).toFixed(2)} MB)
                </span>
              </div>
              <button
                type="button"
                onClick={() => onRemove(index)}
                className="text-red-500 hover:text-red-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
