import React from 'react';
import CompanyForm from './components/CompanyForm';
import Header from './components/Header';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <Header />
      <CompanyForm />
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;